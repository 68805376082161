import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { initImageOptimization } from './utils/imageOptimization';
import { loadScriptDeferred } from './utils/scriptLoader';

// 画像最適化の初期化
initImageOptimization();

// 解析ツールなどの外部スクリプトを遅延読み込み
loadScriptDeferred({
  src: 'https://www.googletagmanager.com/gtag/js?id=G-XXXXXXXXXX',
  attributes: {
    'data-defer': 'true'
  }
});

// アプリケーションのレンダリング
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// パフォーマンス計測（必要な場合のみ）
// 開発環境でのみ実行する
if (process.env.NODE_ENV === 'development') {
  reportWebVitals(console.log);
} else {
  // 本番環境では必要な場合のみ計測データを送信
  reportWebVitals();
}