import React, { lazy, Suspense } from 'react';
import LoadingFallback from '../components/LoadingFallback';

/**
 * コンポーネントを遅延ロードするためのヘルパー関数
 * @param {Function} importFunc - インポート関数
 * @param {Object} fallback - ロード中に表示するコンポーネント
 * @returns {React.LazyExoticComponent} - 遅延ロードされたコンポーネント
 */
export const lazyLoad = (importFunc, fallback = null) => {
  const LazyComponent = lazy(importFunc);
  
  return props => (
    <Suspense fallback={fallback || <LoadingFallback />}>
      <LazyComponent {...props} />
    </Suspense>
  );
};
