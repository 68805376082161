import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import './App.css';

// コンポーネントのインポート
import Header from './components/Header';
import Footer from './components/Footer';
import { lazyLoad } from './utils/lazyLoad';

// 遅延ロードするページコンポーネント
const HomePage = lazyLoad(() => import('./pages/HomePage'));
const FeaturesPage = lazyLoad(() => import('./pages/FeaturesPage'));
const PricingPage = lazyLoad(() => import('./pages/PricingPage'));
const ContactPage = lazyLoad(() => import('./pages/ContactPage'));

// スクロールトップコンポーネント
const ScrollToTop = () => {
  const { pathname } = useLocation();
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  
  return null;
};

function App() {
  return (
    <HelmetProvider>
      <Router>
        <div className="App">
          <ScrollToTop />
          <Header />
          <main>
            <Routes>
              <Route path="/" element={<HomePage />} />
              <Route path="/features" element={<FeaturesPage />} />
              <Route path="/pricing" element={<PricingPage />} />
              <Route path="/contact" element={<ContactPage />} />
            </Routes>
          </main>
          <Footer />
        </div>
      </Router>
    </HelmetProvider>
  );
}

export default App;