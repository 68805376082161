/**
 * 指定したコンポーネントを事前にロードするユーティリティ
 * ユーザーがリンクにホバーした時などに使用することで
 * 実際にページ遷移する前にコンポーネントをロードしておくことができる
 */

/**
 * コンポーネントを事前にロードする
 * @param {Array} componentImports - インポート関数の配列
 */
export const preloadComponents = (componentImports) => {
  if (!Array.isArray(componentImports)) {
    componentImports = [componentImports];
  }
  
  // 各インポート関数を実行してプリロード
  componentImports.forEach(importFunc => {
    importFunc().catch(err => {
      console.warn('コンポーネントのプリロードに失敗しました:', err);
    });
  });
};

/**
 * ナビゲーションリンク用のプリロードハンドラを作成
 * @param {Function} importFunc - プリロードするコンポーネントのインポート関数
 * @returns {Function} - マウスオーバー時に実行されるハンドラ関数
 */
export const createPreloadHandler = (importFunc) => {
  let hasPreloaded = false;
  
  return () => {
    if (!hasPreloaded) {
      preloadComponents(importFunc);
      hasPreloaded = true;
    }
  };
};
