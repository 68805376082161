/**
 * 外部スクリプトを非同期で読み込むためのユーティリティ
 */

/**
 * 外部スクリプトを非同期で読み込む
 * @param {string} src - スクリプトのURL
 * @param {Object} attributes - スクリプトタグに追加する属性
 * @returns {Promise} - スクリプトの読み込みが完了したらresolveするPromise
 */
export const loadScript = (src, attributes = {}) => {
  return new Promise((resolve, reject) => {
    // すでに読み込まれているか確認
    const existingScript = document.querySelector(`script[src="${src}"]`);
    if (existingScript) {
      resolve();
      return;
    }

    const script = document.createElement('script');
    script.src = src;
    script.async = true;

    // 追加の属性を設定
    Object.entries(attributes).forEach(([key, value]) => {
      script.setAttribute(key, value);
    });

    // イベントリスナーを追加
    script.onload = () => resolve();
    script.onerror = (error) => reject(new Error(`スクリプトの読み込みに失敗しました: ${src}`));

    // DOMに追加
    document.body.appendChild(script);
  });
};

/**
 * 複数の外部スクリプトを非同期で読み込む
 * @param {Array} scripts - スクリプト情報の配列 [{src, attributes}]
 * @returns {Promise} - すべてのスクリプトの読み込みが完了したらresolveするPromise
 */
export const loadScripts = (scripts) => {
  return Promise.all(scripts.map(script => {
    if (typeof script === 'string') {
      return loadScript(script);
    }
    return loadScript(script.src, script.attributes);
  }));
};

/**
 * スクリプトを遅延読み込みする
 * ページの読み込みが完了した後にスクリプトを読み込む
 * @param {string|Object} script - スクリプトのURLまたはスクリプト情報オブジェクト
 */
export const loadScriptDeferred = (script) => {
  if (document.readyState === 'complete') {
    // ページがすでに読み込み完了している場合は直ちに読み込む
    if (typeof script === 'string') {
      loadScript(script);
    } else {
      loadScript(script.src, script.attributes);
    }
  } else {
    // ページの読み込みが完了したら読み込む
    window.addEventListener('load', () => {
      if (typeof script === 'string') {
        loadScript(script);
      } else {
        loadScript(script.src, script.attributes);
      }
    });
  }
};
