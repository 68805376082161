import React, { useState, useEffect } from 'react';
import { getOptimizedImagePath } from '../utils/imageOptimization';

/**
 * 最適化された画像を表示するコンポーネント
 * WebPをサポートしているブラウザでは自動的にWebP画像を表示し、
 * サポートしていない場合は元の画像を表示します。
 */
const OptimizedImage = ({ src, alt, width, height, className, style, loading = 'lazy', ...props }) => {
  const [imageSrc, setImageSrc] = useState(src);
  
  useEffect(() => {
    // 画像パスを最適化
    const optimizedSrc = getOptimizedImagePath(src);
    setImageSrc(optimizedSrc);
  }, [src]);
  
  return (
    <img 
      src={imageSrc} 
      alt={alt} 
      width={width} 
      height={height} 
      className={className} 
      style={style}
      loading={loading}
      {...props} 
    />
  );
};

export default OptimizedImage;
