// 画像最適化のためのユーティリティ関数

/**
 * 画像のプリロードを行う関数
 * @param {string} src - 画像のパス
 * @returns {Promise} - 画像のロードが完了したらresolveするPromise
 */
export const preloadImage = (src) => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => resolve(img);
    img.onerror = reject;
    img.src = src;
  });
};

/**
 * WebP対応ブラウザかどうかを確認する関数
 * @returns {boolean} WebPをサポートしているかどうか
 */
export const isWebPSupported = () => {
  const elem = document.createElement('canvas');
  if (!!(elem.getContext && elem.getContext('2d'))) {
    // WebPをサポートしているかどうかを確認
    return elem.toDataURL('image/webp').indexOf('data:image/webp') === 0;
  }
  return false;
};

/**
 * 画像のパスをWebP対応に変換する関数
 * @param {string} src - 元の画像パス
 * @returns {string} - WebP対応パスまたは元のパス
 */
export const getOptimizedImagePath = (src) => {
  // ブラウザがWebPをサポートしているかどうかを確認
  if (typeof window !== 'undefined' && window.localStorage) {
    const webpSupported = localStorage.getItem('webpSupported');
    
    if (webpSupported === 'true' && src && (src.endsWith('.jpg') || src.endsWith('.jpeg') || src.endsWith('.png'))) {
      // .jpgや.pngを.webpに置き換える
      return src.substring(0, src.lastIndexOf('.')) + '.webp';
    }
  }
  
  return src;
};

/**
 * 初期化時にWebPサポートを確認してlocalStorageに保存
 */
export const initImageOptimization = () => {
  if (typeof window !== 'undefined' && window.localStorage) {
    if (localStorage.getItem('webpSupported') === null) {
      const supported = isWebPSupported();
      localStorage.setItem('webpSupported', supported);
    }
  }
};
