import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const FooterContainer = styled.footer`
  background-color: var(--primary-color);
  color: var(--white-color);
  padding: 60px 0 30px;
  position: relative;
  z-index: 10;
`;

const FooterContent = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
  display: grid;
  grid-template-columns: 2fr 1fr 1fr 1fr;
  gap: 40px;
  
  @media (max-width: 992px) {
    grid-template-columns: 1fr 1fr;
  }
  
  @media (max-width: 576px) {
    grid-template-columns: 1fr;
  }
`;

const FooterColumn = styled.div`
  margin-bottom: 20px;
`;

const FooterLogo = styled.div`
  margin-bottom: 20px;
  
  img {
    height: 60px;
    width: auto;
    margin-bottom: 10px;
  }
`;

const FooterDescription = styled.p`
  font-size: 0.95rem;
  line-height: 1.6;
  margin-bottom: 20px;
`;

const SocialLinks = styled.div`
  display: flex;
  gap: 15px;
  margin-top: 20px;
`;

const SocialLink = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.1);
  transition: all 0.3s ease;
  
  &:hover {
    background-color: var(--secondary-color);
    transform: translateY(-3px);
  }
  
  svg {
    width: 18px;
    height: 18px;
    fill: var(--white-color);
  }
`;

const FooterTitle = styled.h3`
  font-size: 1.2rem;
  font-weight: 600;
  margin-bottom: 20px;
  position: relative;
  padding-bottom: 10px;
  
  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 40px;
    height: 3px;
    background-color: var(--secondary-color);
    border-radius: 1.5px;
  }
`;

const FooterLinks = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;

const FooterLink = styled.li`
  margin-bottom: 12px;
  
  a {
    color: rgba(255, 255, 255, 0.8);
    text-decoration: none;
    transition: all 0.3s ease;
    font-size: 0.95rem;
    display: inline-block;
    
    &:hover {
      color: var(--white-color);
      transform: translateX(5px);
    }
  }
`;

const ContactInfo = styled.div`
  margin-bottom: 15px;
  display: flex;
  align-items: flex-start;
  
  svg {
    margin-right: 10px;
    margin-top: 3px;
    flex-shrink: 0;
    width: 16px;
    height: 16px;
    fill: var(--secondary-color);
  }
  
  p {
    margin: 0;
    font-size: 0.95rem;
    line-height: 1.6;
    color: rgba(255, 255, 255, 0.8);
    
    a {
      color: var(--white-color);
      transition: transform 0.3s ease, color 0.3s ease;
      
      &:hover {
        transform: scale(1.05);
        color: var(--secondary-color);
      }
    }
  }
`;

const FooterBottom = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px 20px 0;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  
  @media (max-width: 768px) {
    flex-direction: column;
    gap: 10px;
    text-align: center;
  }
`;

const Copyright = styled.p`
  font-size: 0.9rem;
  color: rgba(255, 255, 255, 0.6);
  margin: 0;
`;

const FooterBottomLinks = styled.nav`
  display: flex;
  gap: 20px;
  
  a {
    color: var(--white-color);
    text-decoration: none;
    font-size: 0.9rem;
    opacity: 0.8;
    transition: opacity 0.3s ease;
    
    &:hover {
      opacity: 1;
    }
  }
`;

const Footer = () => {
  return (
    <FooterContainer>
      <FooterContent>
        <FooterColumn>
          <FooterLogo>
            <Link to="/" aria-label="ぐっとスクールのホームページへ">
              <img src="/images/logo4.jpg" alt="ぐっとスクール" width="auto" height="60" />
            </Link>
          </FooterLogo>
          <FooterDescription>
            生成AI技術を活用した新しい学習体験を提供する岡崎市の学習塾です。
            個別指導と最新テクノロジーを組み合わせた効果的な学習方法で、
            お子様の可能性を最大限に引き出します。
          </FooterDescription>
          {/* <SocialLinks aria-label="SNSリンク">
            <SocialLink href="https://twitter.com/" target="_blank" rel="noopener noreferrer" aria-label="Twitterでフォローする">
              <svg aria-hidden="true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
                <path d="M22.46 6c-.77.35-1.6.58-2.46.69.88-.53 1.56-1.37 1.88-2.38-.83.5-1.75.85-2.72 1.05C18.37 4.5 17.26 4 16 4c-2.35 0-4.27 1.92-4.27 4.29 0 .34.04.67.11.98C8.28 9.09 5.11 7.38 3 4.79c-.37.63-.58 1.37-.58 2.15 0 1.49.75 2.81 1.91 3.56-.71 0-1.37-.2-1.95-.5v.03c0 2.08 1.48 3.82 3.44 4.21a4.22 4.22 0 0 1-1.93.07 4.28 4.28 0 0 0 4 2.98 8.521 8.521 0 0 1-5.33 1.84c-.34 0-.68-.02-1.02-.06C3.44 20.29 5.7 21 8.12 21 16 21 20.33 14.46 20.33 8.79c0-.19 0-.37-.01-.56.84-.6 1.56-1.36 2.14-2.23z" />
              </svg>
            </SocialLink>
            <SocialLink href="https://www.facebook.com/" target="_blank" rel="noopener noreferrer" aria-label="Facebookでいいねする">
              <svg aria-hidden="true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
                <path d="M20 3H4a1 1 0 0 0-1 1v16a1 1 0 0 0 1 1h8.615v-6.96h-2.338v-2.725h2.338v-2c0-2.325 1.42-3.592 3.5-3.592.699-.002 1.399.034 2.095.107v2.42h-1.435c-1.128 0-1.348.538-1.348 1.325v1.735h2.697l-.35 2.725h-2.348V21H20a1 1 0 0 0 1-1V4a1 1 0 0 0-1-1z" />
              </svg>
            </SocialLink>
            <SocialLink href="https://www.instagram.com/" target="_blank" rel="noopener noreferrer" aria-label="Instagramでフォローする">
              <svg aria-hidden="true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
                <path d="M12 2c2.717 0 3.056.01 4.122.06 1.065.05 1.79.217 2.428.465.66.254 1.216.598 1.772 1.153a4.908 4.908 0 0 1 1.153 1.772c.247.637.415 1.363.465 2.428.047 1.066.06 1.405.06 4.122 0 2.717-.01 3.056-.06 4.122-.05 1.065-.218 1.79-.465 2.428a4.883 4.883 0 0 1-1.153 1.772 4.915 4.915 0 0 1-1.772 1.153c-.637.247-1.363.415-2.428.465-1.066.047-1.405.06-4.122.06-2.717 0-3.056-.01-4.122-.06-1.065-.05-1.79-.218-2.428-.465a4.89 4.89 0 0 1-1.772-1.153 4.904 4.904 0 0 1-1.153-1.772c-.248-.637-.415-1.363-.465-2.428C2.013 15.056 2 14.717 2 12c0-2.717.01-3.056.06-4.122.05-1.066.217-1.79.465-2.428a4.88 4.88 0 0 1 1.153-1.772A4.897 4.897 0 0 1 5.45 2.525c.638-.248 1.362-.415 2.428-.465C8.944 2.013 9.283 2 12 2zm0 1.802c-2.67 0-2.986.01-4.04.059-.976.045-1.505.207-1.858.344-.466.182-.8.398-1.15.748-.35.35-.566.684-.748 1.15-.137.353-.3.882-.344 1.857-.048 1.055-.058 1.37-.058 4.041 0 2.67.01 2.986.058 4.04.045.977.207 1.505.344 1.858.182.466.399.8.748 1.15.35.35.684.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058 2.67 0 2.987-.01 4.04-.058.977-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.684.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041 0-2.67-.01-2.986-.058-4.04-.045-.977-.207-1.505-.344-1.858a3.097 3.097 0 0 0-.748-1.15 3.098 3.098 0 0 0-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.055-.048-1.37-.058-4.041-.058zm0 3.063a5.135 5.135 0 1 1 0 10.27 5.135 5.135 0 0 1 0-10.27zm0 8.468a3.333 3.333 0 1 0 0-6.666 3.333 3.333 0 0 0 0 6.666zm6.538-8.469a1.2 1.2 0 1 1-2.4 0 1.2 1.2 0 0 1 2.4 0z" />
              </svg>
            </SocialLink>
          </SocialLinks> */}
        </FooterColumn>
        
        <FooterColumn>
          <FooterTitle as="h2">リンク</FooterTitle>
          <nav aria-label="フッターナビゲーション">
            <FooterLinks>
              <FooterLink>
                <Link to="/">ホーム</Link>
              </FooterLink>
              <FooterLink>
                <Link to="/features">特徴・サービス</Link>
              </FooterLink>
              <FooterLink>
                <Link to="/pricing">料金プラン</Link>
              </FooterLink>
              <FooterLink>
                <Link to="/contact">お問い合わせ</Link>
              </FooterLink>
              <FooterLink>
                <Link to="/contact?trial=true">無料体験授業</Link>
              </FooterLink>
            </FooterLinks>
          </nav>
        </FooterColumn>
        
        <FooterColumn>
          <FooterTitle as="h2">サービス</FooterTitle>
          <nav aria-label="サービスナビゲーション">
            <FooterLinks>
              <FooterLink>
                <Link to="/features#ai-learning">AI学習サポート</Link>
              </FooterLink>
              <FooterLink>
                <Link to="/features#digital">デジタル教材</Link>
              </FooterLink>
            </FooterLinks>
          </nav>
        </FooterColumn>
        
        <FooterColumn>
          <FooterTitle as="h2">お問い合わせ</FooterTitle>
          <address style={{ fontStyle: 'normal' }}>
            <ContactInfo>
              <svg aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="currentColor">
                <path d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z" />
              </svg>
              <p>
                <a
                  href="https://maps.app.goo.gl/vPcxhehatBNKLAw2A"
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label="住所の地図リンク"
                >
                  〒444-0917<br />愛知県岡崎市日名西町8-8
                </a>
              </p>
            </ContactInfo>
            <ContactInfo>
              <svg aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="currentColor">
                <path d="M6.62 10.79c1.44 2.83 3.76 5.14 6.59 6.59l2.2-2.2c.27-.27.67-.36 1.02-.24 1.12.37 2.33.57 3.57.57.55 0 1 .45 1 1V20c0 .55-.45 1-1 1-9.39 0-17-7.61-17-17 0-.55.45-1 1-1h3.5c.55 0 1 .45 1 1 0 1.25.2 2.45.57 3.57.11.35.03.74-.25 1.02l-2.2 2.2z" />
              </svg>
              <p>
                <a href="tel:09041839663" aria-label="電話番号 090-4183-9663">090-4183-9663</a>
              </p>
            </ContactInfo>
            <ContactInfo>
              <svg aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="currentColor">
                <path d="M20 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 4l-8 5-8-5V6l8 5 8-5v2z" />
              </svg>
              <p>
                <a href="mailto:guttoschool.okazaki@gmail.com" aria-label="メールアドレス guttoschool.okazaki@gmail.com">guttoschool.okazaki@gmail.com</a>
              </p>
            </ContactInfo>
          </address>
        </FooterColumn>
      </FooterContent>
      
      <FooterBottom>
        <Copyright>© {new Date().getFullYear()} ぐっとスクール. All Rights Reserved.</Copyright>
        {/* <FooterBottomLinks aria-label="プライバシーポリシーと利用規約">
          <a href="/privacy-policy">プライバシーポリシー</a>
          <a href="/terms">利用規約</a>
        </FooterBottomLinks> */}
      </FooterBottom>
    </FooterContainer>
  );
};

export default Footer;
