import React, { useState, useEffect } from 'react';
import { Link, NavLink, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';
import OptimizedImage from './OptimizedImage';
import { createPreloadHandler } from '../utils/preloadComponents';

// ページコンポーネントのプリロードハンドラを作成
const preloadHomePage = createPreloadHandler(() => import('../pages/HomePage'));
const preloadFeaturesPage = createPreloadHandler(() => import('../pages/FeaturesPage'));
const preloadPricingPage = createPreloadHandler(() => import('../pages/PricingPage'));
const preloadContactPage = createPreloadHandler(() => import('../pages/ContactPage'));

const HeaderContainer = styled(motion.header)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  background: rgba(255, 255, 255, 0.95);
  backdrop-filter: blur(10px);
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  height: 60px;
  display: flex;
  align-items: center;
  // padding: 0 1rem;

  @media (max-width: 768px) {
    height: 60px;
    padding: 0 0.5rem;
  }
`;

const HeaderContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;

  @media (max-width: 768px) {
    padding: 0 0.5rem;
  }
`;

const Logo = styled(Link)`
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0;
  height: 60px;
  margin-right: auto;

  img {
    height: 60px;
    width: auto;
    transition: opacity 0.3s ease;
  }

  @media (max-width: 768px) {
    height: 60px;

    img {
      height: 60px;
    }
  }

  &:hover img {
    opacity: 0.8;
  }
`;

const MobileHeaderCTA = styled(Link)`
  display: none;
  background-color: var(--secondary-color);
  color: var(--white-color);
  padding: 6px 12px;
  border-radius: 30px;
  text-decoration: none;
  font-weight: 600;
  font-size: 0.85rem;
  margin-right: 10px;
  white-space: nowrap;
  transition: all 0.3s ease;
  
  @media (max-width: 992px) {
    display: inline-block;
  }
  
  &:hover {
    background-color: #f57c00;
    color: var(--white-color);
  }
`;

const Nav = styled.nav`
  display: flex;
  align-items: center;
  padding-top: 0;
  
  @media (max-width: 992px) {
    display: none;
  }
`;

const NavList = styled.ul`
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
`;

const NavItem = styled.li`
  margin: 0 5px;
`;

const NavLinkStyled = styled(NavLink)`
  display: block;
  padding: 8px 15px;
  color: var(--dark-text-color);
  text-decoration: none;
  font-weight: 500;
  transition: color 0.3s ease;
  position: relative;
  
  &::after {
    content: '';
    position: absolute;
    bottom: 3px;
    left: 15px;
    right: 15px;
    height: 2px;
    background-color: var(--secondary-color);
    transform: scaleX(0);
    transition: transform 0.3s ease;
  }
  
  &:hover, &.active {
    color: var(--primary-color);
    
    &::after {
      transform: scaleX(1);
    }
  }
  
  &.active::after {
    transform: scaleX(1);
  }
`;

const CTAButton = styled(Link)`
  display: inline-block;
  background-color: var(--secondary-color);
  color: var(--white-color);
  padding: 8px 20px;
  border-radius: 30px;
  text-decoration: none;
  font-weight: 600;
  margin-left: 15px;
  transition: all 0.3s ease;
  
  &:hover {
    background-color: #f57c00;
    transform: translateY(-3px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    color: var(--white-color);
  }
`;

const MobileMenuButton = styled.div`
  display: none;
  cursor: pointer;
  padding: 8px 10px;
  padding-top: 8px;
  margin-left: auto;
  
  @media (max-width: 992px) {
    display: block;
  }
  
  svg {
    width: 24px;
    height: 24px;
    fill: ${props => props['data-scrolled'] === 'true' ? 'var(--primary-color)' : 'var(--white-color)'};
    transition: fill 0.3s ease;
  }
`;

const HamburgerButton = styled.button`
  display: none;
  background: none;
  border: none;
  cursor: pointer;
  padding: 10px;
  z-index: 1001;
  
  @media (max-width: 992px) {
    display: block;
  }
  
  span {
    display: block;
    width: 30px;
    height: 3px;
    margin: 6px 0;
    background-color: var(--primary-color);
    transition: all 0.3s ease;
    
    @media (max-width: 768px) {
      width: 35px;
      height: 4px;
      margin: 7px 0;
    }
  }
  
  &.active {
    span:nth-child(1) {
      transform: rotate(-45deg) translate(-8px, 6px);
      
      @media (max-width: 768px) {
        transform: rotate(-45deg) translate(-9px, 7px);
      }
    }
    
    span:nth-child(2) {
      opacity: 0;
    }
    
    span:nth-child(3) {
      transform: rotate(45deg) translate(-8px, -6px);
      
      @media (max-width: 768px) {
        transform: rotate(45deg) translate(-9px, -7px);
      }
    }
  }
`;

const MobileMenu = styled(motion.div)`
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  width: 70%;
  max-width: 300px;
  height: 100vh;
  background-color: var(--white-color);
  z-index: 1000;
  padding: 80px 20px 20px;
  overflow-y: auto;
  box-shadow: -5px 0 15px rgba(0, 0, 0, 0.1);
  
  @media (max-width: 992px) {
    display: block;
  }
  
  @media (max-width: 480px) {
    width: 80%;
    max-width: 280px;
  }
`;

const MobileMenuOverlay = styled(motion.div)`
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
  
  @media (max-width: 992px) {
    display: block;
  }
`;

const MobileNavList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`;

const MobileNavItem = styled.li`
  margin: 15px 0;
  
  @media (max-width: 768px) {
    margin: 20px 0;
  }
`;

const MobileNavLink = styled(NavLink)`
  display: block;
  padding: 10px 0;
  color: var(--dark-text-color);
  text-decoration: none;
  font-weight: 500;
  font-size: 1.2rem;
  transition: color 0.3s ease;
  
  @media (max-width: 768px) {
    font-size: 1.4rem;
    padding: 12px 0;
  }
  
  &:hover, &.active {
    color: var(--primary-color);
  }
`;

const MobileCTAButton = styled(Link)`
  display: inline-block;
  background-color: var(--secondary-color);
  color: var(--white-color);
  padding: 12px 25px;
  border-radius: 30px;
  text-decoration: none;
  font-weight: 600;
  margin-top: 20px;
  transition: all 0.3s ease;
  
  &:hover {
    background-color: #f57c00;
    color: var(--white-color);
  }
`;

const CloseButton = styled.button`
  position: absolute;
  top: 20px;
  right: 20px;
  background: none;
  border: none;
  cursor: pointer;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  
  &::before, &::after {
    content: '';
    position: absolute;
    width: 24px;
    height: 3px;
    background-color: var(--primary-color);
    border-radius: 2px;
  }
  
  &::before {
    transform: rotate(45deg);
  }
  
  &::after {
    transform: rotate(-45deg);
  }
  
  &:hover::before, &:hover::after {
    background-color: var(--secondary-color);
  }
`;

const MobileButtonsContainer = styled.div`
  display: none;
  align-items: center;
  
  @media (max-width: 992px) {
    display: flex;
  }
`;

const Header = () => {
  const [scrolled, setScrolled] = useState(false);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const location = useLocation();
  
  useEffect(() => {
    const handleScroll = () => {
      setScrolled(window.scrollY > 50);
    };
    
    window.addEventListener('scroll', handleScroll);
    
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  
  const toggleMobileMenu = () => {
    setMobileMenuOpen(!mobileMenuOpen);
  };
  
  const closeMobileMenu = () => {
    setMobileMenuOpen(false);
  };
  
  const handleLogoClick = (e) => {
    if (location.pathname === '/') {
      e.preventDefault();
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  };
  
  return (
    <HeaderContainer
      initial={{ y: -100 }}
      animate={{ y: 0 }}
      transition={{ duration: 0.5 }}
      style={{
        background: scrolled ? 'rgba(255, 255, 255, 0.95)' : 'rgba(255, 255, 255, 0.8)',
      }}
    >
      <HeaderContent>
        <Logo to="/" onClick={handleLogoClick}>
          <img src="/images/logo.jpg" alt="ぐっとスクール" height="60" style={{ width: 'auto', objectFit: 'contain' }} />
        </Logo>
        
        <Nav>
          <NavList role="menubar">
            <NavItem role="none">
              <NavLinkStyled 
                to="/" 
                role="menuitem"
                aria-current={location.pathname === "/" ? "page" : undefined}
                onMouseOver={preloadHomePage}
              >
                ホーム
              </NavLinkStyled>
            </NavItem>
            <NavItem role="none">
              <NavLinkStyled 
                to="/features" 
                role="menuitem"
                aria-current={location.pathname === "/features" ? "page" : undefined}
                onMouseOver={preloadFeaturesPage}
              >
                特徴・サービス
              </NavLinkStyled>
            </NavItem>
            <NavItem role="none">
              <NavLinkStyled 
                to="/pricing" 
                role="menuitem"
                aria-current={location.pathname === "/pricing" ? "page" : undefined}
                onMouseOver={preloadPricingPage}
              >
                料金プラン
              </NavLinkStyled>
            </NavItem>
            <NavItem role="none">
              <NavLinkStyled 
                to="/contact" 
                role="menuitem"
                aria-current={location.pathname === "/contact" ? "page" : undefined}
                onMouseOver={preloadContactPage}
              >
                お問い合わせ
              </NavLinkStyled>
            </NavItem>
          </NavList>
          
          <CTAButton to="/contact?trial=true">
            無料体験はこちら
          </CTAButton>
        </Nav>
        
        {/* モバイルボタンコンテナ */}
        <MobileButtonsContainer>
          {/* モバイル用のCTAボタン */}
          <MobileHeaderCTA to="/contact?trial=true">
            無料体験
          </MobileHeaderCTA>
          
          <MobileMenuButton
            onClick={toggleMobileMenu}
            aria-label={mobileMenuOpen ? "メニューを閉じる" : "メニューを開く"}
            role="button"
            tabIndex="0"
          >
            <HamburgerButton className={mobileMenuOpen ? 'active' : ''}>
              <span></span>
              <span></span>
              <span></span>
            </HamburgerButton>
          </MobileMenuButton>
        </MobileButtonsContainer>
        
        <AnimatePresence>
          {mobileMenuOpen && (
            <>
              <MobileMenuOverlay
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.3 }}
                onClick={closeMobileMenu}
              />
              <MobileMenu
                initial={{ x: '100%' }}
                animate={{ x: 0 }}
                exit={{ x: '100%' }}
                transition={{ type: 'tween', duration: 0.3 }}
              >
                <CloseButton 
                  onClick={closeMobileMenu}
                  aria-label="メニューを閉じる"
                />
                <MobileNavList>
                  <MobileNavItem>
                    <MobileNavLink to="/" onClick={closeMobileMenu} onMouseOver={preloadHomePage}>
                      ホーム
                    </MobileNavLink>
                  </MobileNavItem>
                  <MobileNavItem>
                    <MobileNavLink to="/features" onClick={closeMobileMenu} onMouseOver={preloadFeaturesPage}>
                      特徴・サービス
                    </MobileNavLink>
                  </MobileNavItem>
                  <MobileNavItem>
                    <MobileNavLink to="/pricing" onClick={closeMobileMenu} onMouseOver={preloadPricingPage}>
                      料金プラン
                    </MobileNavLink>
                  </MobileNavItem>
                  <MobileNavItem>
                    <MobileNavLink to="/contact" onClick={closeMobileMenu} onMouseOver={preloadContactPage}>
                      お問い合わせ
                    </MobileNavLink>
                  </MobileNavItem>
                </MobileNavList>
                <MobileCTAButton to="/contact?trial=true" onClick={closeMobileMenu}>
                  無料体験はこちら
                </MobileCTAButton>
              </MobileMenu>
            </>
          )}
        </AnimatePresence>
      </HeaderContent>
    </HeaderContainer>
  );
};

export default Header; 